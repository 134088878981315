<template>
  <main>
    <div class="main-background main-background--photo main-background--dark">
      <img
        v-if="results?.image"
        :src="`${BASEURL}/assets/${results.image}`"
        alt=""
      />
    </div>

    <div class="title-date container">
      <div class="title">
        <span class="title__text text-white">
          {{ type }}
        </span>

        <div class="title__background bg-purple"></div>
      </div>

      <div class="date text-black bg-white">
        <span class="date__long"> {{ date_formated }} </span>
      </div>
    </div>

    <div class="content container">
      <h1 class="content__title text-white line-effect line-effect--big">
        {{ results.title ?? '' }}
      </h1>

      <h2 class="content__subtitle text-purple">
        {{ results.baseline ?? '' }}
      </h2>

      <p class="content__animate text-white">{{ results.speaker }}</p>

      <div class="tarif">
        <div
          v-for="price in prices"
          :key="price"
          class="tarif__card border-white"
        >
          <div class="tarif__title text-black bg-white">
            {{ price.low_description }}
          </div>

          <div class="tarif__price text-white">{{ price.price }} €</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import useDateFormat from '@/components/utils/UseDateFormat.vue';
import axios from "axios";

export default defineComponent({
  name: "tarif-product",
  props: {
    slide: {
      type: Number,
      default: 21
    }
  },
  setup(props) {
    Array.prototype.first = function () {
      return this[0];
    };
    const { sentence } = useDateFormat();
    const results = ref({});
    const prices = ref({});
    const start_date = ref({});
    const show_hours = ref(true)
    const end_date = ref({});
    const date_formated = ref({});
    const type = ref({});

    const TOKEN = "i8jd2qf";
    const BASEURL = "https://api.abbaye-st-jacut.com";
    const ENDPOINT =
      `https://api.abbaye-st-jacut.com/items/application_totem?fields=single_product.product_id.title, single_product.product_id.image,single_product.product_id.speaker,single_product.product_id.baseline,single_product.product_id.product_schedule.start_date,single_product.product_id.product_schedule.show_hours,single_product.product_id.product_schedule.end_date,single_product.product_id.product_price.low_description,single_product.product_id.product_price.price,single_product.product_id.type.product_type_id.parent.title,single_product.product_id.type.product_type_id.title&filter={"id": { "_eq": "${props.slide}" },"type":{"_eq":"single_product"}}`;

    onMounted(async () => {
      const req = await axios
        .create({
          baseURL: BASEURL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .get(ENDPOINT);

      if (req.status == 200) {
        results.value = req.data.data.first().single_product.first().product_id,
        
        prices.value = results.value.product_price.map(p => ({ ...p, price: Number(p.price).toFixed(2) }))
        
        start_date.value = results.value.product_schedule.first().start_date
        show_hours.value = results.value.product_schedule.first().show_hours
        
        end_date.value = results.value.product_schedule.first().end_date

        //On récupère le type de produit parent, si il n'en possède pas on récupère celui courant
        if (results.value.type.first().product_type_id.parent) {
          type.value = results.value.type.first().product_type_id.parent?.title;
        } else {
          type.value = results.value.type.first().product_type_id.title;
        }
        
        date_formated.value = sentence({ start_date: start_date.value, end_date: end_date.value })
      }
    });

    return {
      results,
      prices,
      date_formated,
      type,
      BASEURL,
    };
  },
});
</script>